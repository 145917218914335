html {
    scroll-behavior: smooth;
}

body * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


:root {
    /* Primary Colors */
    --hoox-color-slate-primary: #768692;
    --hoox-color-slate-secondary: #414D57;
    --hoox-color-slate-medium: #98A4AE;
    --hoox-color-slate-dark: #4A5761;
    --hoox-color-white: #ffffff;
    --hoox-color-black: #000;
    --hoox-color-slate-tertiary: #F8F8F8;
    --hoox-color-slate-brown: #6F6F6F;
    --hoox-color-grey-secondary: #6F6F6F;

    /* Secondary Colors */
    --hoox-color-laurel: #333431;
    --hoox-color-sand: #EDE1CF;
    --hoox-color-lilac: #F0E4EA;
    --hoox-color-bone: #D2CDC3;
    --hoox-color-yellow: #FDEE9C;
    --hoox-color-grey: #FAFAFA;
    --hoox-color-pink: #EADCE9;
    --hoox-color-grey2: #E6E6E6;
    --hoox-color-grey3: #E5E7E9;

    /* Accent Colors */
    --hoox-color-sunshine: #FFE900;
}


/* Bg Colors */
.bg-lilac {
    background-color: var(--hoox-color-lilac);
}

.bg-grey {
    background-color: #FAFAFA;
}

.bg-sunshine {
    background-color: var(--hoox-color-sunshine);
}


/* Typography */
.ff-century {
    font-family: var(--font-heading);
}


.hoox-title {
    color: var(--hoox-color-slate-secondary);
    font-size: 28px;
    font-weight: 400;
    line-height: normal;
}

.hoox-title--hero {
    color: var(--hoox-color-slate-secondary);
    font-size: 28px;
    font-weight: 400;
    line-height: normal;
}

.hoox-title-secondary {
    color: var(--hoox-color-slate-secondary);
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
}

.hoox-subtitle {
    color: var(--hoox-color-slate-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
}

.hoox-text {
    color: var(--hoox-color-slate-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
}

.hoox-italic {
    font-style: italic;
}

@media (min-width: 768px) {
    .hoox-title {
        font-size: 40px;
    }

    .hoox-title--hero {
        font-size: 48px;
    }
    
    .hoox-subtitle {
        font-size: 18px;
    }
    
    .hoox-text {
        line-height: 150%;
    }
}

/* Button */
.hoox-btn-wrapper {
    display: flex;
    width: 100%;
}

@media (min-width: 768px) {
    .hoox-btn.hoox-btn--fixed-width {
        min-width: 320px;
    }
}

.hoox-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--hoox-color-slate-secondary);
    height: 47px;

    color: var(--hoox-color-white);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    transition: .3s all;
    min-width: 100%;
}


/* Utility Classes */
.text-align-center {
    text-align: center;
}


/* Images */
.hoox-img-wrapper {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.hoox-image {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Hoox Wrapper */
.hoox-wrapper {
    max-width: 1250px;
    margin-inline: auto;
    padding-inline: 25px;
}

/*Sticky Header*/

#shopify-section-template--14594120646691__e899cdb9-0f77-4919-b996-7541ac3dd97f {
    position: sticky !important;
    top: 0 !important;
    background: var(--hoox-color-white);
    z-index: 999;
}

.mb-title {
    margin-bottom: 16px !important;
}

@media (min-width: 768px) {
    .mb-title {
        margin-bottom: 8px !important;
    }
}
